<template>
  <div class="page_pingjia1">
    <div class="card">
      <!-- 居中 -->
      <van-row type="flex" justify="center">
        <van-col span="6">
          <van-image
              class="logo"
              fit="contain"
              :src="bgUrl"
          />
          <van-image
              class="jianjie"
              width="100%"
              height="50%"
              fit="contain"
              :src="src"
          /></van-col>
        <van-col :span="data.acceptedByName=='自助终端'?16:18">
          <div class="textName">
            <van-icon v-if="data.acceptedByName!='自助终端' " name="friends-o" class="card_name"><span>姓名:{{data.acceptedByName}}</span></van-icon>
            <van-icon v-if="data.acceptedByName!='自助终端' " name="coupon-o" class="card_name1"><span>工号:{{data.acceptedById}}</span></van-icon>
            <div class="card_name2 card_name"><van-rate v-model="value" size="75"/></div>
            <div class="card_name2 card_name card_field">
              <van-field
                  v-model="message"
                  rows="4"
                  size="large"
                  autosize
                  label="建议"
                  type="textarea"
                  maxlength="50"
                  placeholder="请输入留言"
                  show-word-limit
              />
            </div>
            <div class="card_button">
              <van-button block size="large" @click="submitFeedback" type="primary">提交</van-button>
            </div>
          </div>
        </van-col>
        <van-col v-if="data.acceptedByName=='自助终端'" span="2" class="timeToExit">
          {{time}}秒后自动评价
        </van-col>
      </van-row>
    </div>
  </div>
</template>

<script>
import {SystemUrl}  from  '@/settings'
import request from "@/api";
export default {
  components: {

  },
  data() {
    return {
      src: require('@/assets/img/touxiang.jpg'),
      value:0,
      message:'',
      SystemUrl:SystemUrl,
      data:'',
      myVar:'',
      time:50,
      timer: null,
    }
  },
  created() {
    if (this.$route.query.data){
      this.data=JSON.parse(this.$route.query.data)
      if (this.data.acceptedByName=='自助终端'){
        this.countDown()
      }else {
        this.myVar=setTimeout(()=>{
          this.value=5
          this.data["evaluateSource"]=1
          this.submitFeedback()
        },50000)
      }
    }
  },
  computed: {
    bgUrl(){
      return require("@/assets/img/"+this.SystemUrl+"_company.png")
    }
  },
  methods: {
    countDown(){
      this.timer = setInterval(() => {
        if (this.time >=1) {
          this.time--
        }else{
          this.value=5
          this.data["evaluateSource"]=1
          this.submitFeedback()
          clearInterval(this.timer)
        }
      }, 1000)
    },
    submitFeedback(){
      if (!this.value){
        this.$toast.fail('请选择满意度')
        return
      }
      this.data["score"]=this.value
      this.data["opinion"]=this.message
      request.post(process.env.VUE_APP_BASE_URL_ip+'/evaluate/save',this.data).then(res=>{
        this.$toast.success("感谢您的评价！")
        if (this.data.acceptedByName!='自助终端'){
          android.close()
          clearTimeout(this.myVar)
        }else{
          clearTimeout(this.myVar)
          setTimeout(()=>{
            window.history.back(-1)
          },1000)
        }
      }).catch(err=>{
        if (this.data.acceptedByName!='自助终端'){
          android.close()
        }else{
          clearTimeout(this.myVar)
          setTimeout(()=>{
            window.history.back(-1)
          },1000)
        }
        this.$toast.fail(err.message)
      }).finally(()=>{
        if (this.data.acceptedByName!='自助终端'){
          android.close()
        }else{
          clearTimeout(this.myVar)
          setTimeout(()=>{
            window.history.back(-1)
          },1000)
        }
        this.$toast.success("感谢您的评价！")
      })
    },
    change(e) {
      console.log('change', e);
    },
  }
}
</script>

<style lang="scss">
.timeToExit {
  height: 18px;
  color: rgba(214, 37, 27, 1);
  font-size: 18px;
  text-align: left;
  margin-top:20px;
}
.page_pingjia1 {
  background: linear-gradient(to top right, #a8b3c5, #b1b1b1);
  width: 100vw;
  height: 100vh;
}
.logo-box {
  position: absolute;

  @media screen and (max-width: 500px){
    top: 12vh;
    left: 20px;
    width: 350px;
    height: 70px;
  }
  @media screen and (min-width: 500px) and (max-width: 1208px){
    top: 12vh;
    left: 13vh;
    width: 350px;
    height: 70px;
  }
  @media screen and (max-width: 1680px)  and (min-width: 1209px){
    top: 13vh;
    left: 13vh;
    width: 450px;
    height: 90px;
  }
  @media screen and (min-width: 1681px){
    top: 11vh;
    left: 20vh;
    width: 520px;
    height: 120px;
  }
  img {
    width: 100%;
    height: 100%;
  }
}
.card{
  width:90%;
  height:80%;
  margin-left:5%;
  margin-top:5%;
  float: left;
  border-radius: 10px;
  background: linear-gradient(to top right, #bac3e0, #999999);
  /* 阴影部分 */
  box-shadow: 0 0 17px #333;
}
.jianjie {
  background-color: #ffffff;
  height:240px;
  width:160px;
  border-radius: 10px;
  margin-top:20%;
  margin-left:80px;
}
.logo{
  width:400px;
  height:100px;
}
.textName{
  margin-top:12%;
  text-align: left;
  font-size: 30px;
}
.card_name{
  margin-left:150px;
}
.card_name1{
  margin-left:80px;
}
.card_name2{
  margin-top:50px;
}
.card_field{
  width:50%;
}
.card_button{
  margin-top:50px;
  margin-left: 200px;
  width: 300px;
}

</style>
